import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenStatic } from "axios";
import useAuthToken from "hooks/useAuthToken";
import { EventDispatchLogout, EventDispatchSnackbar } from "router/Router";
import { getSearchParams } from "utils";

interface Interceptor {
  onFulfilled: (config: AxiosRequestConfig) => AxiosRequestConfig;
  onRejected: (error: AxiosError) => Promise<AxiosError>;
}

export interface HttpClientOptions extends AxiosRequestConfig {
  requestInterceptors?: Interceptor[];
  responseInterceptors?: Interceptor[];
}

export interface ApiResponse<T> extends AxiosResponse {
  page?: any;
  result?: any;
  itemsCount?: number;
  pageCount?: number;
  pageSize?: number;
  pageNumber?: number;
  responseData: T;
  hasError: boolean;
  message: any;
}

export default class HttpClient {
  private readonly axios: AxiosInstance;
  private authToken: string | null;

  CancelToken: CancelTokenStatic;
  componentDidMount() {
    // Equivalent to useEffect(() => { ... }, [])
    this.authToken = useAuthToken.getAuthTokenFromLocalStorage();
  }

  componentWillUnmount() {
    // Clean up any resources or subscriptions
    // Equivalent to useEffect(() => { return () => { ... } }, [])
  }

  constructor(baseURL: string, options: HttpClientOptions = {}) {
    const { requestInterceptors = [], responseInterceptors = [], headers = {}, ...rest } = options;

    this.authToken = useAuthToken.getAuthTokenFromLocalStorage();

    if (this.authToken) {
      headers.Authorization = `Bearer ${this.authToken}`;
    }

    type SearchParams = { [key: string]: string | number | boolean };
    const searchParams: SearchParams = getSearchParams();

    // Convert the entire searchParams object to a JSON string and assign it to headers
    headers["refabric_param"] = JSON.stringify(searchParams);

    this.CancelToken = axios.CancelToken;

    this.axios = axios.create({
      baseURL,
      headers,
      ...rest,
    });

    this.axios.interceptors.request.use((config) => {
      if (config.headers) {
        config.headers["cache-control"] = "no-cache";
      } else {
        config.headers = { "cache-control": "no-cache" };
      }

      return config;
    });

    this.axios.interceptors.response.use(
      (conf) => conf,
      (error) => {
        if (error.response?.status === 403) {
          //token expired
          useAuthToken.clearAuthTokenFromLocalStorage();
          window.dispatchEvent(new CustomEvent(EventDispatchLogout));
        } else if (error.response?.status === 402) {
          window.dispatchEvent(new CustomEvent(EventDispatchSnackbar, { detail: { type: 402 } }));
        } else if (error.response?.status === 409) {
          window.dispatchEvent(new CustomEvent(EventDispatchSnackbar, { detail: { type: 409 } }));
        }

        return Promise.reject(error);
      }
    );

    requestInterceptors.forEach((interceptorConfig) => {
      this.axios.interceptors.request.use(
        (conf) => interceptorConfig.onFulfilled(conf),
        (error) => interceptorConfig.onRejected(error)
      );
    });

    responseInterceptors.forEach((interceptorConfig) => {
      this.axios.interceptors.response.use(
        (conf) => interceptorConfig.onFulfilled(conf),
        (error) => interceptorConfig.onRejected(error)
      );
    });
  }

  async request(options: AxiosRequestConfig): Promise<AxiosResponse> {
    const response = await this.axios.request(options);

    return response.data;
  }

  async get<T>(path: string, options: AxiosRequestConfig = {}): Promise<ApiResponse<T>> {
    const headers = {
      ...this.axios.defaults.headers.common,
      ...options.headers,
      Authorization: `Bearer ${this.authToken}`,
    };
    const response = await this.axios.get(path, { ...options, headers });

    return response.data;
  }

  async post<T, D = unknown>(path: string, data: D, options?: AxiosRequestConfig<D>): Promise<ApiResponse<T>> {
    const headers = {
      ...this.axios.defaults.headers.common,
      ...options?.headers,
      Authorization: `Bearer ${this.authToken}`,
    };

    const response = await this.axios.post(path, data, { ...options, headers });

    return response.data;
  }

  async put<T, D = unknown>(path: string, data: D, options: AxiosRequestConfig<D>): Promise<ApiResponse<T>> {
    const headers = {
      ...this.axios.defaults.headers.common,
      ...options.headers,
      Authorization: `Bearer ${this.authToken}`,
    };
    const response = await this.axios.put(path, data, { ...options, headers });

    return response.data;
  }

  async patch<T, D = unknown>(path: string, data: D, options: AxiosRequestConfig<D>): Promise<ApiResponse<T>> {
    const headers = {
      ...this.axios.defaults.headers.common,
      ...options.headers,
      Authorization: `Bearer ${this.authToken}`,
    };

    const response = await this.axios.patch(path, data, { ...options, headers });

    return response.data;
  }

  async delete<T>(path: string, options: AxiosRequestConfig): Promise<ApiResponse<T>> {
    const headers = {
      ...this.axios.defaults.headers.common,
      ...options.headers,
      Authorization: `Bearer ${this.authToken}`,
    };

    const response = await this.axios.delete(path, { ...options, headers });

    return response.data;
  }

  getAxiosInstance(): AxiosInstance {
    return this.axios;
  }

  setAuthToken(token: string | null) {
    this.authToken = token;
    if (token) {
      this.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete this.axios.defaults.headers.common["Authorization"];
    }
  }

  static isCancel(error: Error): boolean {
    return axios.isCancel(error);
  }
}
